import { memo, useState } from "react";
import StarComponent from "./StarComponent";
import ImageComponent from "./ImageComponent";
import GoogleLogo from "./GoogleLogo";
import ReviewText from "./ReviewText";
import TranslateButton from "./TranslateButton";
import useReviewText from "./useReviewText";
import LoadingGoogleReview from "./LoadingGoogleReview";

const ReviewCard = memo(({ review, loading }) => {
  const { rating, relativePublishTimeDescription, text, originalText } = review;
  const { displayName } = review.authorAttribution;
  const { showOriginal, toggleText, reviewText } = useReviewText(
    originalText,
    text
  );

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    if (window.matchMedia("(min-width: 640px)").matches) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (window.matchMedia("(min-width: 640px)").matches) {
      setIsHovered(false);
    }
  };

  const handleTouchStart = () => {
    if (!window.matchMedia("(min-width: 640px)").matches) {
      setIsHovered(true);
    }
  };

  const handleTouchEnd = () => {
    if (!window.matchMedia("(min-width: 640px)").matches) {
      setIsHovered(false);
    }
  };
  return (
    <div
      className={`group relative flex flex-col gap-1 self-end border rounded-lg sm:shadow-lg transition-all bg-card sm:w-[350px] w-full p-4 shrink-0 overflow-hidden sm:mr-4 md:mr-6 lg:mr-10 z-10 duration-1000`}
      style={{
        maxHeight: isHovered ? "100%" : "158px", // Expanding to fit content
        minHeight: "158px",
        transition: "max-height 0.5s ease-in-out", // Smooth transition for max-height
      }}
    >
      {loading && (
        <div className="absolute inset-0 z-20 flex items-center justify-center bg-opacity-70 bg-background transition-colors duration-1000">
          <LoadingGoogleReview />
        </div>
      )}
      <div className="flex items-center justify-between grow-0 transition-colors duration-1000">
        <div className="flex">
          <ImageComponent data={review.authorAttribution} />
          <div>
            <h3 className="text-lg font-bold text-foreground text-balance transition-colors duration-1000">
              {displayName}
            </h3>
            <p className="text-sm text-muted-foreground transition-colors duration-1000">
              {relativePublishTimeDescription}
            </p>
          </div>
        </div>
        <GoogleLogo />
      </div>
      <div className="flex items-center justify-between">
        <StarComponent rating={rating} />
        <TranslateButton toggleText={toggleText} showOriginal={showOriginal} />
      </div>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        className="overflow-hidden"
      >
        <ReviewText reviewText={reviewText} />
      </div>
    </div>
  );
});
ReviewCard.displayName = "ReviewCard";

export default ReviewCard;

// import StarComponent from "./StarComponent";
// import ImageComponent from "./ImageComponent";
// import GoogleLogo from "./GoogleLogo";
// import ReviewText from "./ReviewText";
// import TranslateButton from "./TranslateButton";
// import useReviewText from "./useReviewText";

// const ReviewCard = ({ review, loading }) => {
//   const { rating, relativePublishTimeDescription, text, originalText } = review;
//   const { displayName } = review.authorAttribution;
//   const { showOriginal, toggleText, reviewText } = useReviewText(
//     originalText,
//     text
//   );
//   console.log(review);

//   return (
//     <div className="group relative flex flex-col gap-1 self-end border rounded-lg sm:shadow-lg transition-all bg-card sm:w-[350px] w-full duration-500 ease-in-out h-[158px] min-h-[158px] hover:h-full p-4 shrink-0 overflow-hidden sm:mr-4 md:mr-6 lg:mr-10 z-10">
//       <div className="flex items-center justify-between">
//         <div className="flex">
//           <ImageComponent data={review.authorAttribution} />
//           <div>
//             <h3 className="text-lg font-bold text-foreground text-balance">
//               {displayName}
//             </h3>
//             <p className="text-sm text-muted-foreground">
//               {relativePublishTimeDescription}
//             </p>
//           </div>
//         </div>
//         <GoogleLogo />
//       </div>
//       <div className="flex items-center justify-between">
//         <StarComponent rating={rating} />
//         <TranslateButton toggleText={toggleText} showOriginal={showOriginal} />
//       </div>

//       <ReviewText reviewText={reviewText} />
//     </div>
//   );
// };

// export default ReviewCard;

///// --------------------------------------------------------------------------- no transition
// import { useState } from "react";
// import StarComponent from "./StarComponent";
// import ImageComponent from "./ImageComponent";
// import GoogleLogo from "./GoogleLogo";
// import ReviewText from "./ReviewText";
// import TranslateButton from "./TranslateButton";
// import useReviewText from "./useReviewText";

// const ReviewCard = ({ review, loading }) => {
//   const { rating, relativePublishTimeDescription, text, originalText } = review;
//   const { displayName } = review.authorAttribution;
//   const { showOriginal, toggleText, reviewText } = useReviewText(
//     originalText,
//     text
//   );

//   // State to manage hover
//   const [isHovered, setIsHovered] = useState(false);

//   // Determine the height class based on hover state and presence of text
//   const heightClass =
//     isHovered && text?.text ? "min-h-[158px] h-auto" : "h-[158px]";

//   return (
//     <div
//       className={`group relative flex flex-col gap-1 self-end border rounded-lg sm:shadow-lg transition-all bg-card sm:w-[350px] w-full duration-500 ease-in-out ${heightClass} p-4 shrink-0 overflow-hidden sm:mr-4 md:mr-6 lg:mr-10 z-10`}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//     >
//       <div className="flex items-center justify-between grow-0">
//         <div className="flex">
//           <ImageComponent data={review.authorAttribution} />
//           <div>
//             <h3 className="text-lg font-bold text-foreground text-balance">
//               {displayName}
//             </h3>
//             <p className="text-sm text-muted-foreground">
//               {relativePublishTimeDescription}
//             </p>
//           </div>
//         </div>
//         <GoogleLogo />
//       </div>
//       <div className="flex items-center justify-between">
//         <StarComponent rating={rating} />
//         <TranslateButton toggleText={toggleText} showOriginal={showOriginal} />
//       </div>

//       <ReviewText reviewText={reviewText} />
//     </div>
//   );
// };

// export default ReviewCard;
