import { useScroll, useMotionValueEvent } from "framer-motion";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useColorChange = () => {
  const [color, setColor] = useState(true);
  const location = useLocation();
  const { scrollY } = useScroll();

  const isHomePage = location.pathname === "/";

  useMotionValueEvent(scrollY, "change", (y) => {
    if (isHomePage) {
      if (y > 35) {
        setColor(false); // Change to color if scrolled past 35
      } else {
        setColor(true); // Reset color if scrolled back above threshold
      }
    }
  });

  useEffect(() => {
    if (!isHomePage) {
      // Reset color state when navigating away from home page
      setColor(false);
    } else {
      setColor(true);
    }
  }, [isHomePage]); // Only depend on isHomePage

  return color;
};

export default useColorChange;
