import { useState, useEffect } from "react";

const useGoogleReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  const apiKey = import.meta.env.VITE_GOOGLE_API_KEY;
  const placeId = import.meta.env.VITE_GOOGLE_PLACE_ID;

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch(
          `https://places.googleapis.com/v1/places/${placeId}?fields=id,displayName,reviews,rating&key=${apiKey}`
        );
        const data = await response.json();
        console.log(data);
        if (data.reviews) {
          // Filter out reviews without text and for five-star reviews
          const fiveStarReviews = data.reviews
            .filter((review) => review.text && review.text.text) // Check if review.text exists and has a non-empty string
            .filter((review) => review.rating === 5); // Then filter for five-star reviews

          // Limit to the last 10 five-star reviews
          setReviews(fiveStarReviews.slice(0, 10));
        }
      } catch (error) {
        console.error("Failed to fetch reviews:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching data
      }
    };

    fetchReviews();
  }, [apiKey, placeId]);

  return { reviews, loading };
};

export default useGoogleReviews;
// ================================================================== V1 ========================================================================================================
// import { useState, useEffect } from "react";

// const useGoogleReviews = () => {
//   const [reviews, setReviews] = useState([]);
//   const [loading, setLoading] = useState(true); // Add loading state

//   const apiKey = import.meta.env.VITE_GOOGLE_API_KEY;
//   const placeId = import.meta.env.VITE_GOOGLE_PLACE_ID;

//   useEffect(() => {
//     const fetchReviews = async () => {
//       try {
//         const response = await fetch(
//           `https://places.googleapis.com/v1/places/${placeId}?fields=id,displayName,reviews,rating&key=${apiKey}`
//         );
//         const data = await response.json();

//         if (data.reviews) {
//           // Filter out reviews without text and for five-star reviews
//           const fiveStarReviews = data.reviews
//             .filter((review) => review.text && review.text.text) // Check if review.text exists and has a non-empty string
//             .filter((review) => review.rating === 5); // Then filter for five-star reviews

//           // Limit to the last 10 five-star reviews
//           setReviews(fiveStarReviews.slice(0, 10));
//         }
//       } catch (error) {
//         console.error("Failed to fetch reviews:", error);
//       } finally {
//         setLoading(false); // Set loading to false after fetching data
//       }
//     };

//     fetchReviews();
//   }, [apiKey, placeId]);

//   return { reviews, loading };
// };

// export default useGoogleReviews;
// ================================================================== FAKE DATA ========================================================================================================
// import { useState, useEffect } from "react";

// const useGoogleReviews = () => {
//   const [reviews, setReviews] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchReviews = async () => {
//       try {
//         const response = await fetch("/extractedReviews.json");
//         const data = await response.json();

//         console.log("Fetched data from JSON:", data); // Log the entire data

//         if (data) {
//           // Filter for all five-star reviews with valid originalText
//           const fiveStarReviews = data.filter(
//             (review) => review.originalText?.text && review.rating === 5
//           );

//           // Update state with all five-star reviews
//           setReviews(fiveStarReviews);

//           console.log("All five-star reviews:", fiveStarReviews); // Log all five-star reviews
//         }
//       } catch (error) {
//         console.error("Failed to fetch reviews:", error);
//       } finally {
//         setLoading(false); // Set loading to false after fetching data
//       }
//     };

//     fetchReviews();
//   }, []);

//   return { reviews, loading };
// };

// export default useGoogleReviews;

// ================================================================== outScraper DATA ========================================================================================================
// import { useState, useEffect } from "react";

// const useGoogleReviews = () => {
//   const [reviews, setReviews] = useState([]);
//   const [loading, setLoading] = useState(true); // Add loading state

//   const apiKey = import.meta.env.VITE_OUTSCRAPER_API_KEY; // Outscraper API key
//   const placeId = import.meta.env.VITE_GOOGLE_PLACE_ID; // Google Place ID

//   useEffect(() => {
//     const fetchReviews = async () => {
//       try {
//         const response = await fetch(
//           `https://api.outscraper.com/v1/google-places-reviews?query=${placeId}&limit=10`,
//           {
//             headers: {
//               "X-API-KEY": apiKey,
//             },
//           }
//         );

//         const data = await response.json();

//         console.log("Fetched data:", data); // Log the entire data

//         if (data && data.length > 0) {
//           const reviewsData = data.map((review) => ({
//             text: review.text,
//             rating: review.rating,
//           }));

//           // Limit to the last 10 reviews or the specified number
//           setReviews(reviewsData.slice(0, 10));

//           console.log("Fetched reviews:", reviewsData.slice(0, 10)); // Log filtered reviews
//         }
//       } catch (error) {
//         console.error("Failed to fetch reviews:", error);
//       } finally {
//         setLoading(false); // Set loading to false after fetching data
//       }
//     };

//     fetchReviews();
//   }, [apiKey, placeId]);

//   return { reviews, loading };
// };

// export default useGoogleReviews;
