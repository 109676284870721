import MobileDropdown from "./MobileDropdown";

import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import i18next from "i18next";
import { ChevronDown } from "lucide-react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

const MobileMenuItems = ({
  items,
  showMenu,
  setShowMenu,
  openSubmenu,
  setOpenSubmenu,
  ref,
}) => {
  const { t } = useTranslation("navigation");
  const location = useLocation();
  const currentLang = i18next.language;
  const isOpen = openSubmenu === items.title;
  const [isHovered, setIsHovered] = useState(false); // State to track hover
  const closeDropdown = () => {
    showMenu && setShowMenu(false);
  };
  const currentPath = location.pathname.startsWith(items.url);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setOpenSubmenu((prev) => (prev === items.title ? false : items.title));
  };

  const liVariant = {
    opened: {
      opacity: 1,
      y: "0%",
      transition: {
        duration: 0.4,
        ease: "easeOut",
      },
    },
    closed: {
      opacity: 0,
      y: "100%",
      transition: {
        duration: 0.4,
        ease: "easeInOut",
      },
    },
  };

  return (
    <motion.li
      variants={liVariant}
      className={`flex flex-col w-full text-foreground ${
        currentLang === "he" ? "items-end" : "items-start"
      } border-b border-foreground/20 last:border-b-0 py-3`}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      {items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={isOpen ? "true" : "false"}
            onClick={(e) => toggleDropdown(e)}
            className={`flex w-full px-4 text-xl ${
              currentLang === "he" ? "flex-row-reverse" : "flex-row"
            } items-center justify-between`}
          >
            <motion.span
              animate={{ scale: isHovered ? 1.2 : 1 }} // Conditionally scale text based on hover state
              transition={{ duration: 0.3, ease: "easeInOut" }}
              className={`${currentPath ? "text-primary" : ""}`}
            >
              {t(items.title)}
            </motion.span>
            <span
              className={`${
                isOpen
                  ? "rotate-180 transition-transform duration-500 ease-in-out"
                  : "transition-transform duration-500"
              }`}
            >
              <ChevronDown />
            </span>
          </button>
          <AnimatePresence initial={false}>
            {isOpen && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.6 }}
                style={{ overflow: "hidden" }}
                className="w-full"
              >
                <MobileDropdown
                  submenus={items.submenu}
                  t={t}
                  ref={ref}
                  closeDropdown={closeDropdown}
                  currentLang={currentLang}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </>
      ) : (
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "text-primary flex items-center text-xl px-4"
              : "flex items-center text-xl text-foreground px-4"
          }
          to={items.url}
          onClick={closeDropdown}
        >
          <motion.span
            className="w-full"
            animate={{ scale: isHovered ? 1.2 : 1 }} // Conditionally scale text based on hover state
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            {t(items.title)}
          </motion.span>
        </NavLink>
      )}
    </motion.li>
  );
};

export default MobileMenuItems;
