import { useEffect } from "react";
import { useUser } from "../components/ui/Auth/useUser";
import { useNavigate } from "react-router-dom";
import LoadingIndicator from "@/components/common/LoadingIndicator";

function RedirectAuthenticatedUser({ children }) {
  const navigate = useNavigate();
  const { isPending, isAuthenticated } = useUser();

  useEffect(() => {
    if (isAuthenticated && !isPending) {
      navigate("/admin"); // Redirect to /admin if authenticated
    }
  }, [isAuthenticated, isPending, navigate]);

  // While checking authentication status, show nothing or loading indicator
  if (isPending || isAuthenticated) {
    return <LoadingIndicator />; // Show a loading spinner while the status is being checked
  }

  // Only render the login page if the user is not authenticated
  return children;
}

export default RedirectAuthenticatedUser;
