import { useTranslation } from "react-i18next";
function TranslateButton({ toggleText, showOriginal }) {
  const { t } = useTranslation();
  return (
    <button
      onClick={toggleText}
      className="text-blue-500 dark:text-blue-400 transition-colors duration-1000"
    >
      {showOriginal ? t("googleEnglishKey") : t("googleOriginalKey")}
    </button>
  );
}

export default TranslateButton;
