import { Sun } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useTheme } from "../../context/ThemeProvider";
import Moon from "./Moon";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useEffect } from "react";
import useColorChange from "@/hooks/useColorChange";
export function ModeToggle() {
  const { theme, setTheme } = useTheme();
  const { t } = useTranslation();
  const color = useColorChange();
  const currentLangisHeb = i18next.language === "he";
  const colorChange = `${color ? "text-white" : "text-foreground"}`;
  useEffect(() => {
    const metaThemeColor = document.querySelector("#theme-color-meta");

    if (theme === "dark") {
      metaThemeColor.setAttribute("content", "#0c0a09"); // Dark mode color
    } else if (theme === "light") {
      metaThemeColor.setAttribute("content", "##FFFFFF"); // Light mode color
    } else {
      // Handle 'system' case or set a default
      const systemTheme = window.matchMedia("(prefers-color-scheme: dark)")
        .matches
        ? "#0c0a09" // Dark mode for system
        : "##FFFFFF"; // Light mode for system
      metaThemeColor.setAttribute("content", systemTheme);
    }
  }, [theme]); // Re-run whenever the theme changes

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          className={`bg-transparent border-0 ${colorChange}`}
        >
          <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
          <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
          {/* 
          <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" /> */}
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="font-varela">
        <DropdownMenuItem
          onClick={() => setTheme("light")}
          disabled={theme === "light"}
          className={`${currentLangisHeb ? "justify-end" : ""}`}
        >
          {t("lightKey")}
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => setTheme("dark")}
          disabled={theme === "dark"}
          className={`${currentLangisHeb ? "justify-end" : ""}`}
        >
          {t("darkKey")}
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => setTheme("system")}
          disabled={theme === "system"}
          className={`${currentLangisHeb ? "justify-end" : ""}`}
        >
          {t("systemKey")}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
