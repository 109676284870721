function GoogleLogo() {
  const linkToReviews =
    "https://www.google.com/search?sca_esv=5ac10ce223656a07&sca_upv=1&hl=en&authuser=0&tbm=lcl&sxsrf=ADLYWILw699iVLTKgrHIm9b45fwHI-B9sQ:1727292186078&q=Paula%27s+Reviews&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIxNDM3NrMwNjcwtjQ2MjUxMDExNdvAyPiKkT8gsTQnUb1YISi1LDO1vHgRK7oIAGW41Do_AAAA&rldimm=16736837039325404456&sa=X&ved=2ahUKEwjsr7Lp6N6IAxXnTKQEHYEwN34Q9fQKegQILRAF&biw=1512&bih=823&dpr=2#lkt=LocalPoiReviews";

  return (
    <a
      href={linkToReviews}
      target="_blank"
      rel="noopener noreferrer"
      className="self-start"
    >
      <img src="/googleLogo.svg" alt="Google" className="h-5 w-5" />
    </a>
  );
}

export default GoogleLogo;
