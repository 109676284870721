import ReviewCard from "./reviewCard/ReviewCard";

const ReviewsList = ({ reviews, loading }) => {
  return (
    <div className="hidden overflow-hidden sm:flex w-full group pb-4 absolute [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)] bottom-0">
      <div className="flex shrink-0 animate-loop-scroll group-hover:paused">
        {reviews?.map((review, index) => (
          <ReviewCard key={index} review={review} loading={loading} />
        ))}
      </div>
      <div
        className="flex shrink-0 animate-loop-scroll group-hover:paused"
        aria-hidden="true"
      >
        {reviews?.map((review, index) => (
          <ReviewCard key={index} review={review} loading={loading} />
        ))}
      </div>
    </div>
  );
};

export default ReviewsList;
