import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ReviewCard from "./reviewCard/ReviewCard";

function MobileReviewContainer({ reviews, loading }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const totalReviews = reviews.length;

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % totalReviews);
      }
    }, 5000); // Change every 5 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [isHovered, totalReviews]);

  return (
    <div
      className="sm:hidden flex group w-full absolute mb-4 px-4 transition-all duration-500 ease-in-out bottom-0" // Ensure this is absolutely positioned with bottom: 0
      onTouchStart={() => setIsHovered(true)}
      onTouchEnd={() => setIsHovered(false)}
    >
      <div className="overflow-hidden w-full">
        <motion.div
          className="flex w-full shrink-0"
          initial={{ x: 0 }}
          animate={{ x: -currentIndex * 100 + "%" }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          {reviews?.map((review, index) => (
            <motion.div
              key={index}
              initial={{ height: 158 }} // Initial height
              animate={{
                height: isHovered ? "auto" : 158, // Expand on hover, collapse otherwise
              }}
              transition={{ duration: 0.5, ease: "easeOut" }} // Smooth transition
              style={{
                minWidth: "100%", // Ensure it takes full width
              }}
            >
              <ReviewCard review={review} loading={loading} />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
}

export default MobileReviewContainer;
