import { motion } from "framer-motion";
import { useEffect } from "react";
const variants = {
  hidden: { opacity: 0 },
  enter: { opacity: 1 },
  exit: { opacity: 0 },
};

const AnimatedLayout = ({ children }) => {
  useEffect(() => {
    // Correctly structure the options object for scrollTo
    window.scrollTo({
      top: 0,
      behavior: "instant", // Note: "instant" is not a valid option. Use "auto" or "smooth".
    });
  }, []);
  return (
    <motion.div
      initial="hidden"
      animate="enter"
      exit="exit"
      variants={variants}
      transition={{ duration: 1, type: "easeInOut", delay: 0.2 }}
      className="relative"
    >
      {children}
    </motion.div>
  );
};

export default AnimatedLayout;
