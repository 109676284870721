import useLogout from "./useLogout";
import { MdLogout } from "react-icons/md";
import { Button } from "@/components/ui/button";

function Logout() {
  const { logout, isPending } = useLogout();

  return (
    <Button
      disabled={isPending}
      onClick={logout}
      variant="outline"
      size="icon"
      className="bg-transparent"
    >
      <MdLogout />
    </Button>
  );
}

export default Logout;
