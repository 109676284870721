import forkSvg from "/fryingPan.gif";
import { useTranslation } from "react-i18next";

function LoadingIndicator() {
  const { t } = useTranslation();
  return (
    <div className="h-dvh w-full flex items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <img src={forkSvg} className="size-40 lg:size-44 xl:size-52" />

        <p className="text-foreground xl:text-xl md:text-lg text-md animate-pulse">
          {t("loadingKey")}
        </p>
      </div>
    </div>
  );
}

export default LoadingIndicator;
