export const menuItemsData = [
  {
    title: "Home",
    url: "/",
  },
  { title: "Contact", url: "/contact" },
  { title: "About", url: "/about" },
  {
    title: "Serving Plates",
    url: "/servingPlates",
    submenu: [
      { title: "dairy", url: "/servingPlates/dairy" },
      { title: "meaty", url: "/servingPlates/meat" },
      { title: "desserts", url: "/servingPlates/desserts" },
    ],
  },
  {
    title: "Catering",
    url: "/catering",
    submenu: [
      {
        title: "brazilianGrill",
        description: "keyBrazilian",
        url: "/catering/brazilianGrill",
      },
      {
        title: "smokedMeats",
        description: "keySmokedMeat",
        url: "/catering/smokedMeat",
      },
      {
        title: "mexican",
        description: "keyMexican",
        url: "/catering/mexican",
      },
      {
        title: "sushi",
        description: "keySushi",
        url: "/catering/sushi",
      },
      {
        title: "asian",
        description: "keyAsian",
        url: "/catering/asian",
      },
      {
        title: "italian",
        description: "keyItalian",
        url: "/catering/italian",
      },
      {
        title: "Empanadas",
        description: "keyEmpanadas",
        url: "/catering/empanadas",
      },
      {
        title: "dessertTable",
        description: "keyDesertTable",
        url: "/catering/dessertTable",
      },
      {
        title: "churrosTable",
        description: "keyChurrosTable",
        url: "/catering/churrosStand",
      },
      {
        title: "privateChef",
        description: "keyPrivateChef",
        url: "/catering/privateChef",
      },
      {
        title: "diaryTable",
        description: "keyDairies",
        url: "/catering/diaryTable",
      },
    ],
  },
];
