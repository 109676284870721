import { useEffect, useRef, useState } from "react";
import { menuItemsData } from "../menuItemsData";
import MobileMenuItems from "./MobileMenuItems";
import { Sling as Hamburger } from "hamburger-react";
import { motion, AnimatePresence } from "framer-motion";
import useColorChange from "@/hooks/useColorChange";

const MobileNav = ({ showMenu, setShowMenu }) => {
  const [openSubmenu, setOpenSubmenu] = useState(false); // use boolean instead of null
  let ref = useRef();
  let hamburgerRef = useRef();
  const color = useColorChange();
  const colorChange = `${
    color ? (!showMenu ? "text-white" : "text-foreground") : "text-foreground"
  }`;
  useEffect(() => {
    const handler = (event) => {
      if (
        showMenu &&
        ref.current &&
        !ref.current.contains(event.target) &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [showMenu, setShowMenu]);

  // Reset openSubmenu when showMenu is closed
  useEffect(() => {
    if (!showMenu) {
      setOpenSubmenu(false); // reset to false
    }
  }, [showMenu]);
  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [showMenu]);
  const mobileMenuVariant = {
    opened: {
      y: "0%",
      transition: {
        delay: 0.1,
        duration: 0.5,
        ease: [0.74, 0, 0.19, 1.02],
      },
    },
    closed: {
      y: "-100%",
      transition: {
        delay: 0.3,
        duration: 0.5,
        ease: [0.74, 0, 0.19, 1.02],
      },
    },
  };

  const ulVariant = {
    opened: {
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.08,
      },
    },
    closed: {
      transition: {
        staggerChildren: 0.02,
        staggerDirection: -1,
      },
    },
  };

  return (
    <nav className="relative lg:hidden flex items-center font-varela">
      <button
        ref={hamburgerRef}
        className={`relative z-50 ${colorChange} rounded-md ring-offset-background transition-colors duration-300 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2`}
      >
        <Hamburger
          label="Show menu"
          easing="ease-in"
          duration={0.3}
          toggled={showMenu}
          toggle={setShowMenu}
          size={21}
        />
      </button>
      <AnimatePresence>
        {showMenu && (
          <motion.div
            variants={mobileMenuVariant}
            initial="closed"
            animate="opened"
            exit="closed"
            className="h-dvh flex left-0 top-0 flex-col fixed bg-secondary w-full z-40"
            ref={ref}
          >
            <motion.ul
              variants={ulVariant}
              className="w-full mt-24 h-full px-6 overflow-y-auto"
            >
              {menuItemsData.map((menu, index) => (
                <MobileMenuItems
                  items={menu}
                  key={index}
                  showMenu={showMenu}
                  setShowMenu={setShowMenu}
                  openSubmenu={openSubmenu}
                  setOpenSubmenu={setOpenSubmenu}
                />
              ))}
            </motion.ul>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default MobileNav;
