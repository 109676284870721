import { lazy } from "react";
const ProtectedRoute = lazy(() => import("./ProtectedRoute"));

const Sandwiches = lazy(() => import("@/pages/servingPlates/Sandwiches"));
const DiaryPage = lazy(() => import("@/pages/servingPlates/diary/DiaryPage"));
const CreateEdit = lazy(() => import("@/components/data/CreateEdit"));
const CreateEditSandwich = lazy(() =>
  import("@/components/data/CreateEditSandwich")
);
const Pasta = lazy(() => import("@/pages/servingPlates/Pasta"));

const DairyRoutes = [
  {
    path: "/servingPlates/dairy",
    element: <DiaryPage />,
    children: [
      {
        path: "createEdit",
        element: (
          <ProtectedRoute>
            <CreateEdit />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/servingPlates/dairy/sandwiches",
    element: <Sandwiches />,
    children: [
      {
        path: "createEditSandwich",
        element: (
          <ProtectedRoute>
            <CreateEditSandwich />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/servingPlates/dairy/pasta", // New child route for Pasta
    element: <Pasta />, // The component to render for this route
    children: [
      {
        path: "createEdit", // Route for creating and editing pasta
        element: (
          <ProtectedRoute>
            <CreateEdit />
          </ProtectedRoute>
        ),
      },
    ],
  },
];

export default DairyRoutes;
