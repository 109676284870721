import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import "./i18n";
import LoadingIndicator from "./components/common/LoadingIndicator.jsx";
window.addEventListener("vite:preloadError", (event) => {
  console.error("Vite preload error:", event);
  event.preventDefault();
  window.location.reload(); // Reload the page in case of preload errors
});
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.Suspense fallback={<LoadingIndicator />}>
    <App />
  </React.Suspense>
);
