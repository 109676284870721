import { useContext, useState, useRef, useEffect } from "react";
import { AuthenticationContext } from "../../context/AuthContext";
import Logout from "../ui/Auth/Logout";
import LangButton from "../common/LangButton";
import { ModeToggle } from "../common/DarkModeToggle";
import MobileNav from "./NavigationComponents/MobileNav/MobileNav";
// import Navigation from "./NavigationComponents/WindowNav/Navigation";
import ShadNav from "./NavigationComponents/WindowNav/ShadNav";
import Logo from "../common/Logo";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import { useLocation } from "react-router-dom";

function Header() {
  const { isLoggedIn } = useContext(AuthenticationContext);
  const [color, setColor] = useState(false);

  const [isHidden, setIsHidden] = useState(false);
  const { scrollY } = useScroll();
  const lastYRef = useRef(0);
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const prevLocationRef = useRef(location.pathname);
  const [transitionDuration, setTransitionDuration] = useState(0.2);
  useEffect(() => {
    if (prevLocationRef.current !== location.pathname) {
      console.log("Page changed:", location.pathname);
      setTransitionDuration(0.8); // Set duration to 1 if the page changed
      prevLocationRef.current = location.pathname; // Update previous location
    } else {
      setTransitionDuration(0.2); // Set duration to 0.5 if the page did not change
    }
  }, [location.pathname]);
  useMotionValueEvent(scrollY, "change", (y) => {
    if (y > 35) {
      setColor(true);
    } else {
      setColor(false);
    }
  });
  useMotionValueEvent(scrollY, "change", (y) => {
    const difference = y - lastYRef.current;
    if (Math.abs(difference) > 100) {
      setIsHidden(difference > 0);
      lastYRef.current = y;
    }
  });

  return (
    <motion.header
      animate={isHidden && !showMenu ? "hidden" : "visible"}
      whileHover="visible"
      onFocusCapture={() => setIsHidden(false)}
      variants={{
        hidden: {
          y: "-260%",
          opacity: 0,
          transition: { duration: 0.5, ease: "easeInOut" },
        },
        visible: {
          y: "0%",
          opacity: 1,
          transition: { duration: transitionDuration, ease: "easeInOut" },
        },
      }}
      className={` ${
        color
          ? "backdrop-blur-xl bg-background/40"
          : "bg-transparent dark:bg-transparent"
      } flex flex-row items-center justify-between top-0 py-2 lg:py-4 left-0 w-full z-30 fixed px-3 transition-colors duration-700 ease-in-out`}
    >
      <div className="w-auto flex items-center justify-start">
        <Logo />
      </div>

      <ShadNav />
      <div className="flex items-center gap-1 w-auto justify-end">
        {isLoggedIn && <Logout />}
        <LangButton />
        <ModeToggle />
        <MobileNav showMenu={showMenu} setShowMenu={setShowMenu} />
      </div>
    </motion.header>
  );
}

export default Header;
