function StarComponent({ rating }) {
  // Create an array of 5 items for the star ratings
  const maxRating = 5;
  const fullStars = Math.floor(rating); // Full stars
  const emptyStars = maxRating - fullStars; // Empty stars
  return (
    <div className="flex items-center">
      {[...Array(fullStars)].map((_, i) => (
        <span
          key={i}
          className="text-yellow-500 text-lg dark:text-yellow-400 transition-colors duration-1000"
        >
          ★
        </span>
      ))}
      {[...Array(emptyStars)].map((_, i) => (
        <span
          key={i}
          className="text-gray-300 text-lg dark:text-gray-500 transition-colors duration-1000"
        >
          ★
        </span>
      ))}
    </div>
  );
}

export default StarComponent;
