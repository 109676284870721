import MobileReviewContainer from "./MobileReviewContainer";
import ReviewsList from "./ReviewsList";
import useGoogleReviews from "./useGoogleReviews";

const GoogleReviews = () => {
  const { reviews, loading } = useGoogleReviews();

  return (
    <>
      <ReviewsList reviews={reviews} loading={loading} />
      <MobileReviewContainer reviews={reviews} loading={loading} />
    </>
  );
};

export default GoogleReviews;
