import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    supportedLngs: ["en", "es", "he"], // Supported languages
    fallbackLng: "he", // Fallback to Hebrew if no language detected
    debug: false,
    returnObjects: true,
    detection: {
      // Detect language from these sources in order
      order: [
        "localStorage",
        "cookie",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],

      // Cache language in these places
      caches: ["localStorage", "cookie"],

      // Customize cache behavior (localStorage & cookie)
      lookupLocalStorage: "i18nextLng", // localStorage key for language
      cookieMinutes: 10080, // Cookie duration (7 days)
      cookieOptions: { path: "/", sameSite: "strict" },
    },
  });

export default i18next;
