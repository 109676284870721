// import { Link } from "react-router-dom";
import { Button } from "../ui/button";
import { SiFacebook, SiLinkedin, SiInstagram } from "react-icons/si";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import useScreenSize from "@/hooks/useScreenSize";
const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();
  const currentLangIsHe = i18next.language === "he";
  const screenSize = useScreenSize();

  return (
    <footer
      dir={currentLangIsHe ? "rtl" : "ltr"}
      className="w-full h-[180px] flex flex-row items-center justify-between bg-secondary bottom-0 px-4"
    >
      <div className="flex gap-2 w-1/3 items-center flex-col">
        <div className="flex items-center justify-center text-center lg:text-start">
          <p>{t("copyright", { year: currentYear })}</p>
          {/* <Button variant="link" asChild>
            <Link className="text-secondary-foreground">תקנון האתר</Link>
          </Button>
          <Button variant="link" asChild>
            <Link className="text-secondary-foreground">מדיניות האתר</Link>
          </Button> */}
        </div>
      </div>
      <div className="flex justify-center items-center w-1/3 min-w-[150px]">
        <img
          src="/images/PaulasBigLogo.png"
          alt="Paula's Logo"
          className="size-44" // Adjust size as needed
        />
      </div>
      <div className="flex items-center flex-col w-1/3">
        <p className="text-secondary-foreground">{t("followUs")}</p>
        <div className="flex gap-2">
          <Button size="icon" variant="link" asChild>
            <a
              href="https://www.facebook.com/paula.latino.israel"
              title="הפייסבוק שלנו"
              target={screenSize === "sm" ? "_self" : "_blank"}
              rel="noopener noreferrer"
            >
              <SiFacebook className="size-6 text-secondary-foreground hover:text-primary transition-colors duration-500" />
            </a>
          </Button>
          <Button size="icon" variant="link" asChild>
            <a
              href="https://www.linkedin.com/company/paula-s/"
              title="הלינקדין שלנו"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SiLinkedin className="size-6 text-secondary-foreground hover:text-primary transition-colors duration-500" />
            </a>
          </Button>
          <Button size="icon" variant="link" asChild>
            <a
              href="https://www.instagram.com/paula_delicias_latinas/"
              title="האינסטגרם שלנו"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SiInstagram className="size-6 text-secondary-foreground hover:text-primary transition-colors duration-500" />
            </a>
          </Button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
