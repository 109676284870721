import { useState } from "react";

const useReviewText = (originalText, text) => {
  const [showOriginal, setShowOriginal] = useState(true); // State to toggle text

  //toggle text display
  const toggleText = () => {
    setShowOriginal((prev) => !prev);
  };

  // Determine which text to show
  const reviewText = showOriginal
    ? originalText?.text || text?.text
    : text?.text || originalText?.text;

  return { showOriginal, toggleText, reviewText };
};

export default useReviewText;
