import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import enIcon from "../../assets/Icons/enIcon.svg";
import heIcon from "../../assets/Icons/heIcon.svg";
import esIcon from "../../assets/Icons/esIcon.svg";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import useColorChange from "@/hooks/useColorChange";

const lngs = {
  en: {
    nativeName: "English",
    icon: enIcon,
    alt: "English Language Icon",
    short: "EN",
  },
  es: {
    nativeName: "Español",
    icon: esIcon,
    alt: "Spanish Language Icon",
    short: "ES",
  },
  he: {
    nativeName: "עברית",
    icon: heIcon,
    alt: "Hebrew Language Icon",
    short: "עב",
  },
};
function LangButton() {
  const ref = useRef();
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const color = useColorChange();
  useEffect(() => {
    const handler = (event) => {
      if (isOpen && ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [isOpen]);
  // const closeDropdown = () => {
  //   isOpen && setIsOpen(false);
  // };
  function handleToggle(e) {
    e.stopPropagation();
    setIsOpen((prev) => !prev);
  }

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const colorChange = `${color ? "text-white" : "text-foreground"}`;
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          className={`bg-transparent w-16 flex gap-1 border-0 ${colorChange} transition-colors duration-1000 font-varela`}
          onClick={(e) => handleToggle(e)}
        >
          {/* type="button" onClick={(e) => handleToggle(e)}
        className="inline-flex items-center font-medium justify-center
        p-[1.2rem]text-sm border h-[1.2rem]" > */}
          {/* Display currently selected language native name */}
          <img
            src={lngs[i18n.resolvedLanguage].icon}
            alt={lngs[i18n.resolvedLanguage].alt}
            className="h-5"
          />
          {lngs[i18n.resolvedLanguage].short}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {Object.keys(lngs).map((lng) => (
          <DropdownMenuItem
            key={lng}
            onClick={() => handleChangeLanguage(lng)}
            disabled={i18n.resolvedLanguage === lng}
            type="submit"
            className="flex items-center justify-between font-varela"
          >
            <img src={lngs[lng].icon} className="w-5" alt={lngs[lng].alt} />
            <p>{lngs[lng].nativeName}</p>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default LangButton;
