import React, { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
import { ThemeProvider } from "./context/ThemeProvider";
import AuthContext from "./context/AuthContext";

const Error = React.lazy(() => import("./pages/Error"));
const Login = React.lazy(() => import("./pages/Login"));

import LoadingIndicator from "./components/common/LoadingIndicator";
import Layout from "./components/layout/Layout";
import DairyRoutes from "./routes/DairyRoutes";
import MeatyRoutes from "./routes/MeatyRoutes";
import DessertsRoute from "./routes/DessertsRoute";
import MainPagesRoutes from "./routes/MainPagesRoutes";
import OtherFoodRoutes from "./routes/OtherFoodRoutes";
import RedirectAuthenticatedUser from "./routes/RedirectAuthenicatedUser";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <Error />,
    children: [
      ...MainPagesRoutes,
      ...DairyRoutes,
      ...MeatyRoutes,
      ...DessertsRoute,
      ...OtherFoodRoutes,
    ],
  },
  {
    path: "/paula",
    element: (
      <RedirectAuthenticatedUser>
        <Login />
      </RedirectAuthenticatedUser>
    ),
  },
  {
    path: "*",
    element: <Error />,
  },
]);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider defaultTheme="system" storageKey="vite-ui-theme">
        <AuthContext>
          <Suspense fallback={<LoadingIndicator />}>
            <RouterProvider router={router} />
          </Suspense>
        </AuthContext>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
      <Toaster
        position="top-center"
        gutter={12}
        containerStyle={{ margin: "8px" }}
      />
    </QueryClientProvider>
  );
}

export default App;
