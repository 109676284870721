import { lazy } from "react";

const ProtectedRoute = lazy(() => import("./ProtectedRoute"));
const DessertsPage = lazy(() =>
  import("@/pages/servingPlates/desserts/DessertsPage")
);

const DecoratedCakes = lazy(() =>
  import("@/pages/servingPlates/desserts/decoratedCakes/DecoratedCakes")
);
const CostumDesignedCakes = lazy(() =>
  import(
    "../pages/servingPlates/desserts/costumDesignCakes/CostumDesignedCakes"
  )
);
const CupDesserts = lazy(() =>
  import("@/pages/servingPlates/desserts/cupDesserts/CupDesserts")
);
const Pastries = lazy(() =>
  import("@/pages/servingPlates/desserts/Pasteries/Pastries")
);
const CreateEdit = lazy(() => import("@/components/data/CreateEdit"));

const DessertsRoute = [
  {
    path: "/servingPlates/desserts",
    element: <DessertsPage />,
    children: [
      {
        path: "createEdit",
        element: (
          <ProtectedRoute>
            <CreateEdit />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/servingPlates/desserts/pastries",
    element: <Pastries />,
    children: [
      {
        path: "createEdit",
        element: (
          <ProtectedRoute>
            <CreateEdit />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/servingPlates/desserts/decoratedCakes",
    element: <DecoratedCakes />,
    children: [
      {
        path: "createEdit",
        element: (
          <ProtectedRoute>
            <CreateEdit />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/servingPlates/desserts/costumDesignedCakes",
    element: <CostumDesignedCakes />,
    children: [
      {
        path: "createEdit",
        element: (
          <ProtectedRoute>
            <CreateEdit />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/servingPlates/desserts/cupDesserts",
    element: <CupDesserts />,
    children: [
      {
        path: "createEdit",
        element: (
          <ProtectedRoute>
            <CreateEdit />
          </ProtectedRoute>
        ),
      },
    ],
  },
];

export default DessertsRoute;
