function ImageComponent({ data }) {
  const { displayName, photoUri, uri } = data;

  return (
    <>
      {photoUri && (
        <a href={uri} target="_blank" rel="noopener noreferrer">
          <img
            src={photoUri}
            alt={displayName}
            className="w-12 h-12 rounded-full shadow-lg border-2 border-gray-200 hover:scale-105 transform transition-transform duration-300 mr-4"
          />
        </a>
      )}
    </>
  );
}

export default ImageComponent;
