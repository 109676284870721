import { useQuery } from "@tanstack/react-query";
import { getCurrentUser } from "../../../api/AuthApi";

export function useUser() {
  // getting the current user and storing into cache
  const { isPending, data: user } = useQuery({
    queryKey: ["user"],
    queryFn: getCurrentUser,
  });
  return { isPending, user, isAuthenticated: user?.role == "authenticated" };
}
