import { useTranslation } from "react-i18next";

import { AiOutlineLoading } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";

function LoadingGoogleReview() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center gap-4">
      {/* Updated gap for better spacing */}
      <div className="relative">
        {/* Spinning loading icon */}
        <AiOutlineLoading className="text-foreground animate-spin size-16" />

        {/* Image icon centered within the loading spinner */}
        <FcGoogle className="text-primary absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 size-10" />
      </div>
      <p className="text-foreground xl:text-xl md:text-lg text-md animate-pulse">
        {t("loadingGoogleReviewsKey")}
      </p>
    </div>
  );
}

export default LoadingGoogleReview;
