import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";
import { lazy, Suspense } from "react";
import GoogleReviews from "../data/google/GoogleReviews";
import { Button } from "../ui/button";

// import { FaArrowDown } from "react-icons/fa6";

import { FaArrowDown } from "react-icons/fa";
import { Link } from "react-router-dom";
const PrivateEventsSection = lazy(() => import("./PrivateEventsSection"));
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import useScreenSize from "@/hooks/useScreenSize";
import LoadingIndicator from "../common/LoadingIndicator";
export const HeroSection = () => {
  const { t } = useTranslation("home");
  const screenSize = useScreenSize();
  const currentLangIsHe = i18next.language === "he";
  return (
    <div>
      <TextParallaxContent
        imgUrl="/images/meatImage.webp"
        heading="heroSectionHeaderKey"
        secondHeading="heroSectionSecondHeaderKey"
        subheading="heroSectionSubHeaderKey"
        t={t}
        currentLangIsHe={currentLangIsHe}
        screenSize={screenSize}
      >
        <Suspense fallback={<LoadingIndicator />}>
          <PrivateEventsSection />
        </Suspense>
      </TextParallaxContent>
    </div>
  );
};

const TextParallaxContent = ({
  imgUrl,
  subheading,
  heading,
  children,
  secondHeading,
  t,
  currentLangIsHe,
  screenSize,
}) => {
  return (
    <div>
      <div className="relative h-[150vh]">
        <StickyImage imgUrl={imgUrl} />
        <OverlayCopy
          heading={heading}
          subheading={subheading}
          secondHeading={secondHeading}
          t={t}
          currentLangIsHe={currentLangIsHe}
          screenSize={screenSize}
        />
      </div>
      {children}
    </div>
  );
};

const StickyImage = ({ imgUrl }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

  return (
    <motion.div
      style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",

        top: 0.1,
        scale,
      }}
      ref={targetRef}
      className="sticky z-0 overflow-hidden dark:brightness-[0.4] brightness-[0.5] h-dvh bg-background"
    >
      <motion.div
        className="absolute inset-0"
        style={{
          opacity,
        }}
      />
    </motion.div>
  );
};

const OverlayCopy = ({
  subheading,
  heading,
  secondHeading,
  t,
  currentLangIsHe,
  screenSize,
}) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = screenSize === "sm" ? 50 : 0; // Adjust this value for how much lower you want to scroll (in pixels)
      const y = section.getBoundingClientRect().top + window.scrollY + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };
  return (
    <motion.div
      style={{
        y,
        opacity,
      }}
      ref={targetRef}
      id="home"
      className="absolute left-0 top-0 flex h-dvh md:h-screen min-h-screen w-full flex-col items-center justify-center px-4 md:px-8 lg:px-16"
    >
      {/* New relative container for inner content */}
      <div className="relative w-full h-full flex flex-col items-center sm:justify-center justify-start pt-16 sm:pt-0">
        {/* Heading */}
        <motion.h2
          initial={{ opacity: 0 }} // Start with opacity 0 (hidden)
          whileInView={{ opacity: 1 }} // Animate to opacity 1 when in view
          viewport={{ once: true }} // Only trigger once
          transition={{ duration: 0.5, delay: 0.3, ease: "easeInOut" }} // Set transition duration if needed
          dir={currentLangIsHe ? "rtl" : "ltr"}
          className="text-2xl md:text-3xl lg:text-4xl font-semibold tracking-tight mt-4 sm:mt-0 text-white md:-translate-y-12 text-center transition-colors duration-1000"
        >
          {t(heading)}
        </motion.h2>

        {/* Second Heading */}
        <motion.h1
          initial={{ opacity: 0 }} // Start with opacity 0 (hidden)
          whileInView={{ opacity: 1 }} // Animate to opacity 1 when in view
          viewport={{ once: true }} // Only trigger once
          transition={{ duration: 1, delay: 1.2, ease: "easeInOut" }} // Set transition duration if needed
          dir={currentLangIsHe ? "rtl" : "ltr"}
          className="text-3xl md:text-4xl lg:text-5xl font-extrabold tracking-tight my-4 lg:my-6 sm:my-0 text-white  md:-translate-y-12 text-center transition-colors duration-1000"
        >
          {t(secondHeading)}
        </motion.h1>

        {/* Subheading - Adjust for long text */}
        <motion.h4
          initial={{ opacity: 0 }} // Start with opacity 0 (hidden)
          whileInView={{ opacity: 1 }} // Animate to opacity 1 when in view
          viewport={{ once: true }} // Only trigger once
          transition={{ duration: 1, delay: 2, ease: "easeInOut" }} // Set transition duration if needed
          dir={currentLangIsHe ? "rtl" : "ltr"}
          className={`text-md md:text-lg lg:text-xl font-medium tracking-tight my-4 md:max-w-screen-md ${
            currentLangIsHe ? "lg:max-w-[900px]" : "lg:max-w-[930px]"
          } max-w-[560px] mx-auto text-white  md:-translate-y-12 text-center transition-colors duration-1000`}
        >
          {t(subheading)}
        </motion.h4>

        {/* Buttons */}
        <motion.div
          initial={{ opacity: 0 }} // Start with opacity 0 (hidden)
          whileInView={{ opacity: 1 }} // Animate to opacity 1 when in view
          viewport={{ once: true }} // Only trigger once
          transition={{ duration: 1, delay: 2.3, ease: "easeInOut" }} // Set transition duration if needed
          className={`mt-8 space-y-4 md:space-y-0  flex flex-col ${
            currentLangIsHe
              ? "md:flex-row-reverse md:space-x-reverse md:space-x-4"
              : "md:flex-row md:space-x-4"
          } items-center text-center  md:-translate-y-12`}
        >
          <Button
            asChild
            variant="secondary"
            className="w-full lg:w-auto transition-colors duration-1000"
          >
            <Link to="/contact">{t("getInTouchButtonKey")}</Link>
          </Button>
          <Button
            dir={currentLangIsHe ? "rtl" : "ltr"}
            onClick={() => scrollToSection("private-events")}
            className="w-full lg:w-auto flex items-center gap-2 transition-colors duration-1000"
          >
            {t("exploreOurServicesButtonKey")}
            <FaArrowDown />
          </Button>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }} // Start with opacity 0 (hidden)
          whileInView={{ opacity: 1 }} // Animate to opacity 1 when in view
          viewport={{ once: true }} // Only trigger once
          transition={{ duration: 1, delay: 2.3, ease: "easeInOut" }} // Set transition duration if needed
          className={`w-full ${
            currentLangIsHe ? "translate-y-72" : "translate-y-60"
          } sm:transform-none`}
        >
          <GoogleReviews />
        </motion.div>
      </div>
    </motion.div>
  );
};

export default HeroSection;
