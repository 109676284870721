import AnimatedLayout from "@/components/common/AnimatedLayout";
import LoadingIndicator from "@/components/common/LoadingIndicator";
import HeroSection from "@/components/home/HeroSection";

import { lazy, Suspense } from "react";
const ServingPlatesSection = lazy(() =>
  import("@/components/home/ServingPlatesSection")
);
function Home() {
  return (
    <>
      <AnimatedLayout>
        <HeroSection />
        <Suspense fallback={<LoadingIndicator />}>
          <ServingPlatesSection />
        </Suspense>
      </AnimatedLayout>
    </>
  );
}

export default Home;
