import Home from "@/pages/Home";
import { lazy } from "react";
const ProtectedRoute = lazy(() => import("./ProtectedRoute"));

const About = lazy(() => import("@/pages/About"));
const Admin = lazy(() => import("@/pages/Admin"));
const Contact = lazy(() => import("@/pages/Contact"));

const MainPagesRoutes = [
  { index: true, element: <Home /> },
  { path: "/contact", element: <Contact /> },
  { path: "/about", element: <About /> },
  {
    path: "/admin",
    element: (
      <ProtectedRoute>
        <Admin />
      </ProtectedRoute>
    ),
  },
];

export default MainPagesRoutes;
