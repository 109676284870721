import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { useState } from "react";

const MobileDropdown = ({ submenus, t, closeDropdown, currentLang }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null); // Track the hovered li index

  const ulVariant = {
    opened: {
      transition: {
        delayChildren: 0.01,
        staggerChildren: 0.05,
      },
    },
    closed: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
  };

  const liVariant = {
    opened: {
      opacity: 1,
      y: "0%",
      transition: {
        duration: 0.3,
        ease: "easeOut",
      },
    },
    closed: {
      opacity: 0,
      y: "100%",
      transition: {
        duration: 0.3, // Match the duration with the opened state
        ease: "easeOut", // Match the easing function with the opened state
      },
    },
  };

  return (
    <motion.ul
      className="relative px-7 w-full"
      variants={ulVariant}
      initial="closed"
      animate="opened"
      exit="closed"
    >
      {submenus.map((submenu, index) => (
        <motion.li
          key={index}
          variants={liVariant}
          className={`flex py-1 w-full text-foreground ${
            currentLang === "he" ? "justify-end" : "justify-start"
          } w-full`}
          onHoverStart={() => setHoveredIndex(index)} // Set hovered index
          onHoverEnd={() => setHoveredIndex(null)} // Reset hovered index
        >
          <motion.span
            animate={{ scale: hoveredIndex === index ? 1.2 : 1 }} // Conditionally scale based on hovered li
            transition={{ duration: 0.2, ease: "easeInOut" }}
            style={{ display: "inline-block" }} // Prevents layout shift
          >
            <NavLink
              className={({ isActive }) =>
                isActive ? "text-primary" : "text-foreground"
              }
              onClick={closeDropdown}
              to={submenu.url}
            >
              {t(submenu.title)}
            </NavLink>
          </motion.span>
        </motion.li>
      ))}
    </motion.ul>
  );
};

export default MobileDropdown;
