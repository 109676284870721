function ReviewText({ reviewText }) {
  return (
    <p className="text-card-foreground text-md tracking-tight leading-tight text-pretty overflow-hidden transition-colors duration-1000">
      {reviewText}
    </p>
  );
}

export default ReviewText;
// function ReviewText({ reviewText }) {
//   return (
//     <p className="text-card-foreground text-md tracking-tight leading-tight text-pretty overflow-hidden">
//       {reviewText}
//     </p>
//   );
// }

// export default ReviewText;
