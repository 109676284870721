import React from "react";
import Header from "./Header";

import Footer from "./Footer";
import SpecialBtn from "../common/SpecialBtn";
// Lazy load the Main component
const Main = React.lazy(() => import("./Main"));

function Layout() {
  return (
    <>
      <Header />
      {/* <SpecialBtn /> */}
      <Main />
      <Footer />
    </>
  );
}

export default Layout;
