import * as React from "react";
import { menuItemsData } from "../menuItemsData";

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
  NavigationMenuViewport,
  NavigationMenuIndicator,
} from "@/components/ui/navigation-menu";
import { NavLink, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import useColorChange from "@/hooks/useColorChange";
import image from "../../../../assets/images/tortapink.webp";
import image1 from "../../../../assets/images/smallempanadas.webp";
import image2 from "../../../../assets/images/dairySand.webp";
import image3 from "../../../../assets/images/petitfure2.webp";

export function ShavNav() {
  const [currentImage, setCurrentImage] = useState(image);
  const [isFlipped, setIsFlipped] = useState(false);
  const { t } = useTranslation("navigation");
  const location = useLocation();
  const color = useColorChange();
  const handleImageChange = (newImage) => {
    if (newImage && newImage !== currentImage) {
      setIsFlipped(true);
      setTimeout(() => {
        setCurrentImage(newImage);
        setIsFlipped(false);
      }, 300);
    }
  };

  const isActivePath = (path) => location.pathname === path;
  const currentLang = i18next.language;

  const colorChange = `${color ? "text-white" : "text-foreground"}`;

  return (
    <NavigationMenu className={`hidden lg:flex font-varela`}>
      <NavigationMenuList
        className={`flex ${
          currentLang === "he" ? "flex-row-reverse" : "flex-row"
        }`}
      >
        <NavigationMenuItem>
          <NavigationMenuLink
            asChild
            className={`${navigationMenuTriggerStyle()} bg-transparent xl:text-lg ${colorChange} ${
              currentLang === "he" ? "ml-1" : ""
            }`}
          >
            <NavLink to="/" className={isActivePath("/") ? "" : ""}>
              {t("Home")}
            </NavLink>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink
            asChild
            className={`${navigationMenuTriggerStyle()} ${colorChange} bg-transparent xl:text-lg`}
          >
            <NavLink
              to="/contact"
              className={isActivePath("/contact") ? "" : ""}
            >
              {t("Contact")}
            </NavLink>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger
            className={`bg-transparent xl:text-lg ${colorChange} ${
              currentLang === "he" ? "flex-row-reverse gap-1" : "text-left"
            }${isActivePath("/servingPlates") ? "text-primary" : ""}`}
            onMouseEnter={() => handleImageChange(image)}
          >
            {t("Serving Plates")}
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
              <li className="row-span-3">
                <NavigationMenuLink>
                  <motion.div
                    className="flex h-full w-full"
                    animate={{ rotateY: isFlipped ? 90 : 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <motion.img
                      src={currentImage}
                      alt="Food Image"
                      className="h-full w-full object-cover rounded-md shadow-md"
                      style={{ backfaceVisibility: "hidden" }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.3 }}
                    />
                  </motion.div>
                </NavigationMenuLink>
              </li>
              <ListItem
                to="/servingPlates/dairy"
                title="dairy"
                image={image2}
                handleImageChange={handleImageChange}
                direction={currentLang === "he" ? "rtl" : "ltr"}
              >
                keyDairy
              </ListItem>
              <ListItem
                to="/servingPlates/meat"
                title="meaty"
                image={image1}
                handleImageChange={handleImageChange}
                direction={currentLang === "he" ? "rtl" : "ltr"}
              >
                keyMeaty
              </ListItem>
              <ListItem
                to="/servingPlates/desserts"
                title="desserts"
                image={image3}
                handleImageChange={handleImageChange}
                direction={currentLang === "he" ? "rtl" : "ltr"}
              >
                keyDesserts
              </ListItem>
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger
            className={`bg-transparent xl:text-lg ${colorChange} ${
              currentLang === "he" ? "flex-row-reverse gap-1" : "text-left"
            }`}
          >
            {t("Catering")}
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul
              direction="ltr"
              className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[650px]"
              dir={currentLang === "he" ? "rtl" : "ltr"}
            >
              {menuItemsData[4].submenu.map((subItem) => {
                return (
                  <ListItem
                    key={subItem.title}
                    title={subItem.title}
                    to={subItem.url}
                    direction={currentLang === "he" ? "rtl" : "ltr"}
                    handleImageChange={handleImageChange}
                  >
                    {subItem.description}
                  </ListItem>
                );
              })}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink
            asChild
            className={`${navigationMenuTriggerStyle()} ${colorChange} bg-transparent xl:text-lg`}
          >
            <NavLink to="/about" className={isActivePath("/about") ? "" : ""}>
              {t("About")}
            </NavLink>
          </NavigationMenuLink>
        </NavigationMenuItem>
      </NavigationMenuList>
      <NavigationMenuIndicator />
      <NavigationMenuViewport />
    </NavigationMenu>
  );
}

export default ShavNav;

const ListItem = React.forwardRef(
  (
    { title, to, image, handleImageChange, children, direction, ...props },
    ref
  ) => {
    const isActivePath = (path) => location.pathname === path;
    const currentLang = i18next.language;
    const { t } = useTranslation("navigation");
    return (
      <li onMouseEnter={() => handleImageChange(image)}>
        <NavigationMenuLink asChild>
          <NavLink
            style={{ direction }}
            to={to}
            ref={ref}
            className={`block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground`}
            {...props}
          >
            <div
              className={`${
                currentLang === "he" ? "text-right" : "text-left"
              } ${
                isActivePath(to) ? "font-extrabold" : ""
              } text-sm text-left font-medium leading-none`}
            >
              {t(title)}
            </div>
            <p
              className={`${
                currentLang === "he" ? "text-right" : "text-left"
              } line-clamp-2 text-left text-sm leading-snug text-muted-foreground`}
            >
              {t(children)}
            </p>
          </NavLink>
        </NavigationMenuLink>
      </li>
    );
  }
);

ListItem.displayName = "ListItem";
