import supabase from "../utils/supabase";

export async function login({ email, password }) {
  let { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });
  if (error) throw new Error(error.message);
  // console.log(data);
  return data;
}

//
export async function getCurrentUser() {
  // check if there an active session
  const { data: session } = await supabase.auth.getSession();
  if (!session.session) return null;
  // get the user from supabase if its exist
  const { data, error } = await supabase.auth.getUser();

  if (error) throw new Error(error.message);
  return data?.user;
}
export async function logout() {
  const { error } = await supabase.auth.signOut();
  if (error) throw new Error(error.message);
}
