import { createContext, useEffect, useState } from "react";
import { useUser } from "../components/ui/Auth/useUser";

export const AuthenticationContext = createContext();

function AuthContext({ children }) {
  const { isAuthenticated, isPending: isAuthPending } = useUser();
  const [isLoggedIn, setisLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true" // Retrieve from localStorage
  );

  // Update localStorage whenever isLoggedIn changes
  useEffect(() => {
    localStorage.setItem("isLoggedIn", isLoggedIn);
  }, [isLoggedIn]);

  // Update isLoggedIn based on isAuthenticated
  useEffect(() => {
    if (!isAuthPending) {
      setisLoggedIn(isAuthenticated);
    }
  }, [isAuthenticated, isAuthPending]);

  return (
    <AuthenticationContext.Provider
      value={{
        isAuthenticated,
        isLoggedIn,
        setisLoggedIn,
        isAuthPending,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

export default AuthContext;
