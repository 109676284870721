import { useMutation, useQueryClient } from "@tanstack/react-query";
import { logout as logoutApi } from "../../../api/AuthApi";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useContext } from "react";
import { AuthenticationContext } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";

function useLogout() {
  const { t } = useTranslation("toastTranslations");
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setisLoggedIn } = useContext(AuthenticationContext);

  const { mutate: logout, isPending } = useMutation({
    mutationFn: logoutApi,
    onSuccess: () => {
      queryClient.removeQueries();
      navigate("/", { replace: true });
      toast(t("userLogOutKey"), { icon: "👋🏻" });
      setisLoggedIn(false);
    },
  });
  return { logout, isPending };
}

export default useLogout;
