import { lazy } from "react";
const ProtectedRoute = lazy(() => import("./ProtectedRoute"));

const MeatyPage = lazy(() => import("@/pages/servingPlates/meaty/MeatyPage"));
const CreateEdit = lazy(() => import("@/components/data/CreateEdit"));

const MeatyRoutes = [
  {
    path: "/servingPlates/meat",
    element: <MeatyPage />,
    children: [
      {
        path: "createEdit",
        element: (
          <ProtectedRoute>
            <CreateEdit />
          </ProtectedRoute>
        ),
      },
    ],
  },
];

export default MeatyRoutes;
